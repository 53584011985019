.viewDocumentsDrawer .ant-drawer-content-wrapper {
    width: 100vw !important;
}

@media (min-width: 600px) {
    .viewDocumentsDrawer .ant-drawer-content-wrapper {
        width: 90vw !important;
    }
}

@media (min-width: 1440px) {
    .viewDocumentsDrawer .ant-drawer-content-wrapper {
        width: 80vw !important;
    }
}

.viewDocumentsDrawer .ant-drawer-header {
    background-color: #eef4fb !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.viewDocumentsDrawer .ant-drawer-body {
    background-color: #eef4fb !important;
    padding: 10px !important;
    padding-top: 0px !important;
}

.viewDocumentsDrawer .ant-drawer-footer {
    background-color: #eef4fb !important;
    padding: 16px !important;
}