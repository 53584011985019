
@media (min-width: 450px) {
.smallDrawer .ant-drawer-content-wrapper {
        width: 400px !important;
    }
}
@media (min-width: 1024px) {
.smallDrawer .ant-drawer-content-wrapper {
        width: 500px !important;
    }
}

.smallDrawer .ant-drawer-body {
    background-color: #EEF4FB !important;
    padding: 0 !important;
}


.addMember .ant-drawer-content-wrapper{
    width: 100vw !important;
}
@media (min-width: 450px) {
.addMember .ant-drawer-content-wrapper {
        width: 400px !important;
    }
}
@media (min-width: 1024px) {
.addMember .ant-drawer-content-wrapper {
        width: 450px !important;
    }
}

.addMember .ant-drawer-header{
    background-color: #EEF4FB !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.addMember .ant-drawer-body{
    background-color: #EEF4FB !important;
    padding: 10px !important;
    padding-top: 0px !important;
}

.addMember .ant-drawer-footer{
    background-color: #EEF4FB !important;
    border-top: 0 !important;
}
