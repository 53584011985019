.ant-form label {
    font-size: 16px;
    font-weight: 400;
    /* letter-spacing: 0.3px; */
}

.role-space {
    display: inline-block;
    overflow-wrap: break-word; 
    /* word-break: break-all;  */
    /* word-break: break-word; */
    word-wrap: break-word;
}
