.riskAssessmentDrawer .ant-drawer-content-wrapper {
  width: 100vw !important;
}

@media (min-width: 600px) {
  .riskAssessmentDrawer .ant-drawer-content-wrapper {
    width: 90vw !important;
  }
}

@media (min-width: 1440px) {
  .riskAssessmentDrawer .ant-drawer-content-wrapper {
    width: 80vw !important;
  }
}

.riskAssessmentDrawer .ant-drawer-header {
  background-color: #eef4fb !important;
  padding: 10px !important;
  border-bottom: 0 !important;
}

.riskAssessmentDrawer .ant-drawer-body {
  background-color: #eef4fb !important;
  padding: 10px !important;
  padding-top: 0px !important;
}

.riskAssessmentDrawer .ant-drawer-footer {
  background-color: #eef4fb !important;
  padding: 16px !important;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: none !important;
}

.custom-spin-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  /* Keep the height as per your existing setup */
  overflow: hidden;
  /* Prevent scrolling for the spinner container */
}

.customEditor .sun-editor-editable {
  background-color: #F9FAFB;
  padding: 0px;
}

.customEditor .sun-editor {
  border: none;
}