.CustomeFilterDrawer .ant-drawer-content-wrapper {
    width: 100vw !important;
}

@media (min-width: 600px) {
    .CustomeFilterDrawer .ant-drawer-content-wrapper {
        width: calc(100vw - 100px) !important;
    }
}

@media (min-width: 1440px) {
    .CustomeFilterDrawer .ant-drawer-content-wrapper {
        width: 70vw !important;
    }
}

.CustomeFilterDrawer .ant-drawer-header {
    background-color: #EEF4FB !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.CustomeFilterDrawer .ant-drawer-body {
    background-color: #EEF4FB !important;
    padding: 0PX 24px 24PX !important;
    /* padding: 20px !important;*/
    padding-top: 0px !important;
    height: max-content !important;
    /* overflow: auto !important; */
}


/* .CUSTOMEHEIGHT */



@media (max-width: 1081px) {
    .customeHeight {
        height: calc(100% - 64px) !important;
    }
}

@media (min-width: 1081px) {
    .customeHeight {
        height: calc(100% - 40px) !important;
    }
}