.createNewKybLinkDrawer .ant-drawer-content-wrapper {
    width: 100vw !important;
}

@media (min-width: 450px) {
    .createNewKybLinkDrawer .ant-drawer-content-wrapper {
        width: 400px !important;
    }
}

@media (min-width: 1024px) {
    .createNewKybLinkDrawer .ant-drawer-content-wrapper {
        width: 450px !important;
    }
}

.createNewKybLinkDrawer .ant-drawer-body {
    padding: 0px 16px;
    background-color: #EEF4FB !important;
}

.createNewKybLinkDrawer .ant-drawer-header {
    padding: 16px;
    border-bottom: 0 !important;
    background-color: #EEF4FB !important;
}

.createNewKybLinkDrawer .ant-drawer-footer {
    padding: 16px;
    border-top: 0 !important;
    background-color: #EEF4FB !important;
}