.largeDrawer .ant-drawer-content-wrapper{
    width: 100vw !important;
}
@media (min-width: 600px) {
.largeDrawer .ant-drawer-content-wrapper {
        width: calc(100vw - 100px) !important;
    }
}
@media (min-width: 1040px) {
.largeDrawer .ant-drawer-content-wrapper {
        width:70vw !important;
    }
}

@media (min-width: 1240px) {
.largeDrawer .ant-drawer-content-wrapper {
        width:60vw !important;
    }
}

.largeDrawer .ant-drawer-header{
    background-color: #fff !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.largeDrawer .ant-drawer-body{
    background-color: #fff !important;
    padding: 10px !important;
    padding-top: 0px !important;
}
