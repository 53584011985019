
@media (min-width: 425px) {
  .subSmallDrawer .ant-drawer-content-wrapper {
    width: 370px !important;
  }
}
@media (min-width: 1024px) {
  .subSmallDrawer .ant-drawer-content-wrapper {
    width: 450px !important;
  }
}

.subSmallDrawer .ant-drawer-body {
  background-color: #eef4fb !important;
  padding: 0 !important;
}
