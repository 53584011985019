.login-page {
  * {
    font-family: "Inter", sans-serif;
    color: #002060;
    line-height: 1.7;
  }

  .play-button-banner {
    width: 100px;
    height: 100px;
    background-color: #e7f21f;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .play-icon-banner {
    width: 0;
    height: 0;
    margin-left: 8px;
    margin-top: 2px;
    border-left: 25px solid #1d2b2d;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.3 !important;
  }

  p {
    color: #7f7f7f;
    font-weight: 300;
  }

  h1 {
    font-size: 72px;
  }

  @media screen and (max-width: 992px) {
    h1 {
      font-size: 56px;
    }
  }

  h2 {
    font-size: 57px;
  }

  h3 {
    font-size: 36px;
  }

  @media screen and (max-width: 992px) {
    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 32px;
  }

  @media screen and (max-width: 992px) {
    h4 {
      font-size: 28px;
    }
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 18px;
  }

  svg.play-icon path {
    color: white !important;
  }

  span.play-icon svg path {
    color: #7f7f7f !important;
  }

  span.play-icon-w svg path {
    color: #002060 !important;
  }

  span.play-icon-gray svg path {
    color: #002060 !important;
  }

  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    padding-right: var(--bs-gutter-x);
    padding-left: var(--bs-gutter-x);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (max-width: 768px) {

    .container,
    .container-fluid,
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm {
      --bs-gutter-x: 15px;
      --bs-gutter-y: 0;
      padding-right: var(--bs-gutter-x);
      padding-left: var(--bs-gutter-x);
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media (min-width: 576px) {

    .container-sm,
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {

    .container-md,
    .container-sm,
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1290px;
    }
  }

  .mil-btn {
    border: none;
    white-space: nowrap;
    background-color: rgb(242, 116, 87);
    border-radius: 10px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
  }

  .mil-btn.mil-fw {
    width: 100%;
  }

  .mil-btn.mil-sm {
    padding: 0 20px;
    height: 48px;
    font-size: 16px;
  }

  .mil-btn.mil-m {
    padding: 0 30px;
    height: 56px;
    font-size: 16px;
  }

  .mil-btn.mil-md {
    padding: 0 30px;
    height: 72px;
    font-size: 16px;
  }

  @media screen and (max-width: 992px) {
    .mil-btn.mil-md {
      padding: 0 25px;
      height: 62px;
    }
  }

  .mil-btn.mil-border {
    color: rgb(242, 250, 250);
    border: solid 1px rgb(242, 250, 250);
    background-color: transparent;
  }

  .mil-btn.mil-light {
    background-color: rgb(242, 250, 250);
    color: rgb(137, 141, 150);
  }

  .mil-btn.mil-grey {
    background-color: rgb(196, 196, 196);
    color: rgb(255, 255, 255);
  }

  .mil-btn.mil-transp {
    background-color: transparent;
    color: rgb(196, 196, 196);
  }

  /* .mil-btn.mil-add-arrow:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f061";
        margin-left: 15px;
        font-size: 12px;
        transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    }

    .mil-btn.mil-add-play:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f04b";
        margin-left: 15px;
        font-size: 12px;
        transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    } */

  .mil-btn:hover {
    filter: brightness(110%);
  }

  .mil-btn:hover.mil-add-arrow:after {
    transform: translateX(3px);
  }

  .mil-light {
    color: rgb(242, 250, 250);
  }

  .mil-price-card {
    padding: 60px 30px;
    text-align: center;
    border-radius: 40px;
  }

  .mil-price-card .mil-sup-text {
    font-size: 15px;
    margin-left: 5px;
  }

  .mil-price-card.mil-featured {
    background: #f3f4f7;
  }

  .mil-price-card li {
    list-style: none;
  }

  @media screen and (max-width: 768px) {
    .mil-price-card {
      background-color: rgba(196, 196, 196, 0.05);
    }
  }

  /* -------------------------------------------

footer

------------------------------------------- */
  .mil-checkbox-frame {
    display: flex;
    align-items: center;
  }

  .mil-checkbox-frame p {
    padding-left: 15px;
  }

  .mil-checkbox-frame {
    display: flex;
    align-items: center;
  }

  .mil-checkbox-frame p {
    padding-left: 15px;
  }

  .mil-checkbox {
    position: relative;
    height: 24px;
    width: 24px;
  }

  .mil-checkbox label {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(196, 196, 196);
    border-radius: 3px;
    cursor: pointer;
    height: 24px;
    width: 24px;
    left: 0;
    position: absolute;
    top: 0;
  }

  .mil-checkbox label:after {
    border: 2px solid rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .mil-checkbox input[type="checkbox"] {
    visibility: hidden;
  }

  .mil-checkbox input[type="checkbox"]:checked+label {
    background-color: #002060;
    border-color: #002060;
  }

  .mil-checkbox input[type="checkbox"]:checked+label:after {
    opacity: 1;
  }

  footer .mil-footer-list li a {
    color: rgb(137, 141, 150);
    font-weight: 300;
    font-size: 12px;
  }

  footer .mil-footer-list {
    padding-left: 0px;
  }

  footer .mil-footer-logo {
    display: inline-block;
  }

  footer .mil-footer-list li {
    color: #7f7f7f;
    list-style-type: none;
    font-size: 12px;
    font-weight: 300;
  }

  footer .mil-footer-list li a {
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }

  footer .mil-footer-list li a:hover {
    color: #002060;
  }

  footer .mil-footer-list.mil-footer-list-2 {
    display: flex;
  }

  footer .mil-footer-list.mil-footer-list-2 li {
    margin-right: 30px;
    margin-bottom: 0;
  }

  footer .mil-footer-list.mil-footer-list-2 li a {
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }

  footer .mil-footer-list.mil-footer-list-2 li a:hover {
    color: rgb(255, 255, 255);
  }

  @media (max-width: 768px) {
    footer .mil-footer-list.mil-footer-list-2 {
      flex-direction: column;
    }

    footer .mil-footer-list.mil-footer-list-2 li {
      margin-bottom: 15px;
    }
  }

  footer .mil-footer-bottom {
    padding-top: 40px;
    padding-bottom: 60px;
    border-top: solid 1px rgba(137, 141, 150, 0.2);
  }

  footer.mil-footer-with-bg {
    background-color: rgb(242, 250, 250);
  }

  footer.mil-footer-with-bg .mil-footer-bottom {
    border-top: solid 1px rgba(137, 141, 150, 0.2);
  }

  footer.mil-footer-dark {
    background-color: rgb(13, 81, 82);
  }

  footer.mil-footer-dark .mil-footer-bottom {
    border-top: solid 1px rgba(3, 166, 166, 0.2);
  }

  footer.mil-footer-dark-2 {
    background-color: rgb(39, 38, 38);
  }

  footer.mil-footer-dark-2 .mil-footer-bottom {
    border-top: solid 1px rgba(137, 141, 150, 0.2);
  }

  .mil-footer-space-fix {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .mil-subscripe-form-footer {
    position: relative;
  }

  .mil-subscripe-form-footer input {
    background-color: transparent;
    height: 65px;
  }

  .mil-subscripe-form-footer input:focus {
    outline: inherit;
  }

  .mil-subscripe-form-footer button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 22px;
  }

  .mil-subscripe-form-footer button i {
    color: rgb(242, 250, 250);
  }

  .mil-subscripe-form-footer button i.mil-dark {
    color: rgb(13, 81, 82);
  }

  /*# sourceMappingURL=style.css.map */
  footer.mil-footer-with-bg {
    background-color: white;
  }

  footer.mil-footer-with-bg .mil-footer-bottom {
    border-top: solid 1px rgba(137, 141, 150, 0.2);
  }

  .mil-list-2 li {
    position: relative;
    /* padding-left: 56px; */
    list-style-type: none;
    margin-bottom: 30px;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  /* .mil-list-2 li:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: rgb(3, 166, 166);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgb(242, 250, 250);
        position: absolute;
        top: -4px;
        left: 0;
    } */

  .mil-list-2 li:last-child {
    margin-bottom: 0;
  }

  .mil-list-2.mil-type-2 li:after {
    background-color: rgb(3, 166, 166);
    color: rgb(255, 255, 255);
  }

  .mil-list-2.mil-type-3 li:after {
    background-color: rgb(13, 81, 82);
    color: rgb(3, 166, 166);
  }

  .mil-list-2.mil-accent li:after {
    background-color: rgb(242, 116, 87);
  }

  /* -------------------------------------------

form

------------------------------------------- */
  .mil-input {
    width: 100%;
    height: 70px;
    border: solid 1px rgba(137, 141, 150, 0.2);
    font-weight: 600;
    font-size: 16px;
    border-radius: 20px;
    padding: 0 50px 0 20px;
  }

  .mil-input::placeholder {
    font-weight: 600;
    font-size: 14px;
  }

  /* -------------------------------------------

icon box

------------------------------------------- */
  .mil-icon-box {
    display: flex;
    border-radius: 40px;
    flex-direction: column;
  }

  .mil-icon-box img {
    display: block;
    width: 50px;
  }

  .mil-icon-box.mil-with-bg {
    padding: 60px 30px;
    background-color: rgb(242, 250, 250);
  }

  .mil-icon-box.mil-dark {
    padding: 60px 30px;
    background: linear-gradient(0deg, #224d50 0%, #1c6360 100%);
  }

  .mil-icon-box.mil-dark-2 {
    padding: 60px 30px;
    background-color: rgb(39, 38, 38);
  }

  .mil-icon-box.mil-bg-fix {
    padding: 60px 30px;
  }

  .mil-icon-box.mil-center {
    text-align: center;
  }

  .mil-icon-box.mil-center img {
    margin-left: auto;
    margin-right: auto;
  }

  .mil-icon-box.mil-hover {
    padding: 60px 30px;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }

  .mil-icon-box.mil-hover:hover,
  .mil-icon-box.mil-hover.mil-hover-active {
    background-color: rgb(242, 250, 250);
  }

  .mil-icon-box.mil-hover:hover h5,
  .mil-icon-box.mil-hover:hover p,
  .mil-icon-box.mil-hover.mil-hover-active h5,
  .mil-icon-box.mil-hover.mil-hover-active p {
    color: rgb(13, 81, 82);
  }

  @media screen and (max-width: 992px) {
    .mil-icon-box {
      width: 100%;
      height: auto;
    }

    .mil-icon-box.mil-hover {
      background-color: rgb(242, 250, 250);
    }

    .mil-icon-box.mil-hover h5 {
      color: rgb(13, 81, 82);
    }
  }

  @media screen and (max-width: 768px) {
    .mil-icon-box.mil-bg-fix {
      background-color: rgba(196, 196, 196, 0.05);
    }
  }

  .mil-icon-box-2 {
    display: flex;
    align-items: flex-start;
  }

  .mil-icon-box-2 img {
    margin-right: 30px;
  }

  .mil-icon-box-2 p {
    width: 81%;
  }

  .mil-icon-box-2.mil-hover {
    opacity: 0.5;
    cursor: default;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  .mil-icon-box-2.mil-hover .mil-button-transform {
    padding: 0;
    background-color: transparent;
    color: rgb(242, 116, 87);
  }

  .mil-icon-box-2.mil-hover:hover,
  .mil-icon-box-2.mil-hover.mil-hover-active {
    opacity: 1;
  }

  .mil-icon-box-2.mil-hover:hover .mil-button-transform,
  .mil-icon-box-2.mil-hover.mil-hover-active .mil-button-transform {
    background-color: rgb(242, 116, 87);
    padding: 0 30px;
    color: rgb(255, 255, 255);
  }

  @media screen and (max-width: 992px) {
    .mil-icon-box-2.mil-hover {
      flex-direction: column;
      opacity: 1;
    }
  }

  .mil-icon-box-2-frame:hover .mil-icon-box-2.mil-hover {
    opacity: 0.5;
  }

  .mil-icon-box-2-frame:hover .mil-icon-box-2.mil-hover .mil-button-transform {
    padding: 0;
    background-color: transparent;
    color: rgb(242, 116, 87);
  }

  .mil-icon-box-2-frame:hover .mil-icon-box-2.mil-hover:hover {
    opacity: 1;
  }

  .mil-icon-box-2-frame:hover .mil-icon-box-2.mil-hover:hover .mil-button-transform {
    background-color: rgb(242, 116, 87);
    padding: 0 30px;
    color: rgb(255, 255, 255);
  }

  /* -------------------------------------------

top panel

------------------------------------------- */
  .mil-top-panel {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  .mil-top-panel .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mil-top-panel .mil-menu-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mil-top-panel.mil-active {
    background-color: rgb(255, 255, 255);
    height: 100px;
    box-shadow: 0 5px 10px rgba(13, 81, 82, 0.05);
  }

  @media (max-width: 1200px) {
    .mil-top-panel {
      height: 80px;
      background-color: rgb(255, 255, 255);
    }

    .mil-top-panel.mil-active {
      height: 80px;
    }
  }

  /* -------------------------------------------

top menu

------------------------------------------- */
  .mil-top-menu ul {
    display: flex;
  }

  .mil-top-menu ul li {
    list-style-type: none;
    margin-right: 15px;
    position: relative;
  }

  .mil-top-menu ul li:last-child {
    margin-right: 0;
  }

  .mil-top-menu ul li a {
    border-radius: 10px;
    height: 48px;
    padding: 0 12px;
    color: #002060;
    font-weight: 500;
    z-index: 1;
    white-space: nowrap;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }

  .mil-top-menu ul li a:before {
    content: "";
    opacity: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    background: black;
  }

  .mil-top-menu ul li a:hover {
    color: #002060;
  }

  .mil-top-menu ul li.mil-has-children>a {
    padding-right: 12px;
  }

  .mil-top-menu ul li.mil-has-children>a:before {
    right: 24px;
  }

  .mil-top-menu ul li.mil-active>a {
    color: black;
  }

  .mil-top-menu ul li.mil-active>a:before {
    opacity: 1;
  }

  .mil-top-menu ul li ul {
    pointer-events: none;
    min-width: 160px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    position: absolute;
    top: 48px;
    flex-direction: column;
    opacity: 0;
    transform: translateY(10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  .mil-top-menu ul li ul li {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .mil-top-menu ul li ul li:last-child {
    margin-bottom: 0;
  }

  .mil-top-menu ul li ul li a {
    justify-content: flex-start;
    height: 38px;
  }

  .mil-top-menu ul li ul li a:before {
    display: none;
  }

  .mil-top-menu ul li ul li a:hover {
    background-color: #e7f21f;
  }

  .mil-top-menu ul li:hover ul {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }

  .mil-top-panel.mil-active .mil-top-menu ul li:hover ul {
    border-radius: 0 0 10px 10px;
  }

  .mil-dark-1 .mil-top-menu ul li a {
    background-color: transparent;
  }

  .mil-dark-1 .mil-top-menu ul li.mil-active a {
    color: rgb(242, 250, 250);
  }

  .mil-dark-1 .mil-top-menu ul li ul {
    background-color: rgb(3, 166, 166);
  }

  .mil-dark-1 .mil-top-menu ul li ul li a {
    background-color: transparent;
    color: rgb(242, 250, 250);
  }

  .mil-dark-1 .mil-top-menu ul li ul li:hover a {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);
  }

  .mil-dark-1 .mil-top-menu ul li:hover>a {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);
  }

  .mil-dark-1 .mil-top-menu ul li:hover ul {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }

  .mil-dark-1.mil-active {
    background-color: rgb(13, 81, 82);
  }

  .mil-dark-1.mil-active .mil-top-menu ul li ul {
    background-color: rgb(13, 81, 82);
  }

  .mil-dark-2 .mil-top-menu ul li a {
    background-color: transparent;
    color: rgb(242, 250, 250);
    opacity: 0.7;
  }

  .mil-dark-2 .mil-top-menu ul li.mil-active a {
    color: rgb(242, 250, 250);
    opacity: 1;
  }

  .mil-dark-2 .mil-top-menu ul li ul {
    background-color: rgb(39, 38, 38);
  }

  .mil-dark-2 .mil-top-menu ul li ul li a {
    background-color: transparent;
    color: rgb(242, 250, 250);
  }

  .mil-dark-2 .mil-top-menu ul li ul li:hover a {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);
  }

  .mil-dark-2 .mil-top-menu ul li.mil-has-children>a:after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1.25C0.5 1.25 2.63316 3.38316 4 4.75C4 4.75 6.13316 2.61683 7.5 1.25' stroke='%23F8F8F8' stroke-opacity='0.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  .mil-dark-2 .mil-top-menu ul li:hover>a {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);
    opacity: 1;
  }

  .mil-dark-2 .mil-top-menu ul li:hover ul {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }

  .mil-dark-2.mil-active {
    background-color: rgb(39, 38, 38);
  }

  .mil-dark-2.mil-active .mil-top-menu ul li ul {
    background-color: rgb(39, 38, 38);
  }

  @media (max-width: 1200px) {
    .mil-top-menu {
      position: absolute;
      pointer-events: none;
      top: 80px;
      left: 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      opacity: 0;
      transform: translateY(10px);
      box-shadow: 0 5px 5px rgba(13, 81, 82, 0.1);
      padding: 0 0 15px 0;
      transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    }

    .mil-top-menu.mil-active {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }

    .mil-top-menu ul {
      padding: 5px;
      flex-direction: column;
    }

    .mil-top-menu ul li {
      width: 100%;
    }

    .mil-top-menu ul li ul {
      border-radius: 10px !important;
      transform: none !important;
      box-shadow: none;
      position: static;
      opacity: 1;
      max-height: 0;
      padding: 0;
      overflow: hidden;
      background-color: #f3f5f7;
    }

    .mil-top-menu ul li ul li {
      opacity: 0;
      transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    }

    .mil-top-menu ul li:hover a {
      background-color: rgb(255, 255, 255);
    }

    .mil-top-menu ul li:hover ul {
      padding: 5px 0;
      max-height: 400px;
    }

    .mil-top-menu ul li:hover ul li {
      opacity: 1;
    }

    .mil-top-menu ul li:hover ul li a {
      background-color: inherit;
    }

    .mil-top-menu ul li:last-child:hover ul {
      margin-bottom: 0;
    }

    .mil-top-menu ul li.mil-active>a {
      box-shadow: none;
      color: black;
    }
  }

  @media (max-width: 1200px) {
    .mil-dark-1 {
      background-color: rgb(13, 81, 82);
    }

    .mil-dark-1 .mil-top-menu {
      background-color: rgb(13, 81, 82);
    }

    .mil-dark-2 {
      background-color: rgb(39, 38, 38);
    }

    .mil-dark-2 .mil-top-menu {
      background-color: rgb(39, 38, 38);
    }
  }

  /* -------------------------------------------

menu button

------------------------------------------- */
  .mil-menu-btn {
    margin-left: 15px;
    background-color: #f3f5f7;
    padding: 25px 15px;
    border-radius: 10px;
    height: 24px;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  @media screen and (max-width: 1200px) {
    .mil-menu-btn {
      display: flex;
    }
  }

  .mil-menu-btn span,
  .mil-menu-btn span:after,
  .mil-menu-btn span:before {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background: #002060;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: inherit;
  }

  .mil-menu-btn span {
    position: relative;
  }

  .mil-menu-btn span:after,
  .mil-menu-btn span:before {
    position: absolute;
  }

  .mil-menu-btn span:before {
    top: -8px;
  }

  .mil-menu-btn span:after {
    top: 8px;
  }

  .mil-menu-btn.mil-active span {
    transform: rotate(45deg);
  }

  .mil-menu-btn.mil-active span:before {
    transform: translate(0px, 8px) rotate(-90deg);
  }

  .mil-menu-btn.mil-active span:after {
    width: 24px;
    transform: translate(0px, -8px) rotate(-90deg);
  }

  .mil-dark-1 .mil-menu-btn {
    background-color: rgb(3, 166, 166);
  }

  .mil-dark-1 .mil-menu-btn span,
  .mil-dark-1 .mil-menu-btn span:after,
  .mil-dark-1 .mil-menu-btn span:before {
    background-color: rgb(242, 250, 250);
  }

  .mil-dark-2 .mil-menu-btn {
    background-color: rgb(27, 23, 23);
  }

  .mil-dark-2 .mil-menu-btn span,
  .mil-dark-2 .mil-menu-btn span:after,
  .mil-dark-2 .mil-menu-btn span:before {
    background-color: rgb(242, 250, 250);
  }
}