 .AICustomTable .ant-table-row {
     background-color: white !important
 }

 .AICustomTable .ant-table-row:hover {
     background-color: white !important
 }

 .AICustomTable .ant-table table {
     border-collapse: separate !important;
     border-spacing: 0 !important;
 }

 .AICustomTable .ant-table-thead th {
     font-size: 15px !important;
     font-weight: 500 !important;
     padding: 8px !important;
     font-weight: 800 !important;
 }

 .AICustomTable .ant-table-tbody>tr>td:first-child {
     font-weight: 700 !important;
 }

 .AICustomTable .ant-table-tbody tr:first-child>td {
     padding: 0px !important;
     border-bottom: none !important;
 }

 .ant-table-tbody td {
     vertical-align: top !important
 }

 .ant-table-tbody>tr>td:first-child {
     vertical-align: middle !important
 }


 .AICustomTable .ant-table-tbody>tr>td {
     border-bottom: 1px solid #8080809f !important;
     border-right: 1px solid #8080809f !important;
     padding: 8px !important;
 }


 .AICustomTable .ant-table-thead>tr>th {
     border-bottom: 1px solid #8080809f !important;
     border-right: 1px solid #8080809f !important;
     text-align: start !important;
 }

 .AICustomTable .ant-table-tbody>tr>td:last-child,
 .AICustomTable .ant-table-thead>tr>th:last-child {
     border-right: none !important;
 }

 .AICustomTable .ant-table-tbody>tr:last-child>td {
     border-bottom: 1px solid #80808023 !important;
 }

 .AICustomTable ::-webkit-scrollbar-thumb {
     background-color: #cecdcdd7 !important;
     border-radius: 10px !important;
 }

 .AICustomTable ::-webkit-scrollbar-thumb:hover {
     background-color: #cecdcdea !important;
 }