.formSelectField .ant-select-selector{
    border-color: #ADBCC8 !important;
    border-radius: 6px !important;
    height: auto !important;
    max-height: 100px !important;
    overflow: auto !important;
    padding: 5px 10px !important;
    box-shadow: none !important;
    background: #f6fafd !important;
}

.ant-select-selection-item{
    font-weight: 800 !important;
}
.ant-select-multiple .ant-select-selection-item{
    border-radius: 5px !important;
    border: 1px solid #BFBFBF !important;
    font-weight: 500 !important;
}

.ant-select-selection-placeholder, 
.ant-input::placeholder, 
.ant-picker-input input::placeholder, 
.ant-picker-suffix {
    color: #8BA2B2 !important;
    opacity: 0.7 !important;
}

.ant-switch-checked {
    background: #1890ff !important;
}