.ant-table-row {
  background-color: #f0f6fd !important;
}
.ant-table-row:hover {
  background-color: #deecfd !important;
}

.addMemberTable .ant-table-row:hover {
  cursor: pointer;
}

.ant-table-tbody td {
  vertical-align: middle !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.pagination-black .ant-pagination {
  display: flex;
  justify-content: end;
  padding: 0px 16px;
}
.pagination-none .ant-pagination {
  display: none;
}
.pagination-black .ant-pagination-item a {
  color: black !important;
  display: inline-flex;
  border-radius: 10px !important;
}
.pagination-black .ant-pagination-item {
  border-radius: 10px !important;
}

.pagination-black .ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
}
.pagination-black .ant-pagination-item-link span {
  margin: auto !important;
}
.pagination-black .ant-pagination-item-link {
  border-radius: 10px;
}

.pagination-black .ant-pagination-item-link .ant-pagination-item-ellipsis {
  height: 100% !important;
  margin: auto !important;
  top: 50% !important;
  margin-right: 17px !important;
}
.ant-table-cell {
  border-bottom: none !important;
}

.ant-table table {
  border-collapse: separate !important;
  border-spacing: 0 20px !important;
}

.ant-table-thead th {
  color: #000 !important;
  font-weight: bold !important;
  font-size: medium;
}

.ant-table-thead > tr > th {
  background: none !important;
  padding: 0 16px !important;
  text-align: center;
}

.align_start .ant-table-thead > tr > th {
  background: none !important;
  padding: 0 16px !important;
  text-align: start !important;
}
