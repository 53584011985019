.ant-layout.main {
  min-height: 100vh;
}

.ant-menu-item[path="/screening"] {
  display: none !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

::-webkit-scrollbar-track {
  background-color: #f6f6f600 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #a2a2a2e1 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a2a2a2 !important;
}
.apexcharts-legend {
  top: 10px !important;
  right: 10px !important;
}
.apexcharts-donut-labels-total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-message-custom-content{
  display: flex;
  align-items: center;
}

.ant-btn.ant-btn-loading{
  display: flex;
}

.ant-btn>span{
  display: flex;
  align-items: center;
  height: 100%;
}


.ant-avatar-lg{
  min-width: 40px;
}

.ant-select-clear{
  top: 46%;
}

.ant-select-multiple .ant-select-selection-search{
  margin-inline-start:0px;
  display: flex;
  align-items: center;
}

.ant-select-multiple .ant-select-selection-placeholder{
  top: 54%;
}

.ant-select-selection-search{
  margin-inline-start:0px;
  display: flex;
  align-items: center;
}

/* .ant-select-single.ant-select-show-arrow .ant-select-selection-search{
  bottom: 3px;
} */

.ant-select-selection-placeholder{
  top: 54%;
}

.ant-tag{
  font-size: 14px;
}

.ant-btn-primary[disabled]{
    background-color: #5db7f3;
    border-color: #5db7f3;
    color: #fff;
    opacity: 3;
}

.ant-btn-primary[disabled]:hover{
    background-color: #5db7f3;
    border-color: #5db7f3;
    color: #fff;
    opacity: 3;
}


 .onb-embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    margin-bottom: 1em;

  }
  .onb-embed-responsive-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border: 0;
  }

.onb-embed-responsive__16by9 {
    padding-bottom: 56.25%;
}

.onb-embed-responsive__4by3 {
    padding-bottom: 75%;
}
