@media (min-width: 768px) {
    .DocHistoryPreviewDrawer .ant-drawer-content-wrapper {
        width: 80vw !important;
    }
}

@media (min-width: 1024px) {
    .DocHistoryPreviewDrawer .ant-drawer-content-wrapper {
        width: 70vw !important;
    }
}

.DocHistoryPreviewDrawer .ant-drawer-body {
    padding: 0px 16px;
    background-color: #EEF4FB !important;
}

.DocHistoryPreviewDrawer .ant-drawer-header {
    padding: 16px;
    border-bottom: 0 !important;
    background-color: #EEF4FB !important;
}

.DocHistoryPreviewDrawer .ant-drawer-footer {
    padding: 16px;
    border-top: 0 !important;
    background-color: #EEF4FB !important;
}