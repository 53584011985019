@media (min-width: 450px) {
    .viewReport .ant-drawer-content-wrapper {
        width: calc(90vw) !important;
    }
}

@media (min-width: 700px) {
    .viewReport .ant-drawer-content-wrapper {
        width: calc(100vw - 300px) !important;
    }
}

@media (min-width: 1224px) {
    .viewReport .ant-drawer-content-wrapper {
        width: 60vw !important;
    }
}

@media (min-width: 1440px) {
    .viewReport .ant-drawer-content-wrapper {
        width: 50vw !important;
    }
}

.viewReport .ant-drawer-header{
    background-color: #EEF4FB !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.viewReport .ant-drawer-body {
   background-color: #EEF4FB !important;
    padding: 10px !important;
    padding-top: 0px !important;
}

.background-color .ant-drawer-body {
    background-color: #EEF4FB !important;
    padding: 0 !important;
}
