@media (min-width: 640px) {
    .OCRSelectDrawer .ant-drawer-content-wrapper {
        width: 380px !important;
    }
}

.OCRSelectDrawer .ant-drawer-body {
    padding: 0px 16px;
    background-color: #EEF4FB !important;
}

.OCRSelectDrawer .ant-drawer-header {
    padding: 16px;
    border-bottom: 0 !important;
    background-color: #EEF4FB !important;
}

.OCRSelectDrawer .ant-drawer-footer {
    padding: 16px;
    border-top: 0 !important;
    background-color: #EEF4FB !important;
}