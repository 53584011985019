.addSupportingDocuments .ant-drawer-content-wrapper {
  width: 100vw !important;
}
@media (min-width: 450px) {
  .addSupportingDocuments .ant-drawer-content-wrapper {
    width: 420px !important;
  }
}
@media (min-width: 1024px) {
  .addSupportingDocuments .ant-drawer-content-wrapper {
    width: 480px !important;
  }
}

.addSupportingDocuments .ant-drawer-header {
  background-color: #eef4fb !important;
  padding: 10px !important;
  border-bottom: 0 !important;
}

.addSupportingDocuments .ant-drawer-body {
  background-color: #eef4fb !important;
  padding: 10px !important;
  padding-top: 0px !important;
}

.addSupportingDocuments .ant-drawer-footer {
  background-color: #eef4fb !important;
  border-top: 0 !important;
}
