.manageConnectionDrawer .ant-drawer-content-wrapper {
  width: 100vw !important;
}

@media (min-width: 450px) {
.manageConnectionDrawer .ant-drawer-content-wrapper {
        width: 400px !important;
    }
}
@media (min-width: 1024px) {
.manageConnectionDrawer .ant-drawer-content-wrapper {
        width: 450px !important;
    }
}

.manageConnectionDrawer .ant-drawer-header{
    background-color: #EEF4FB !important;
    padding: 10px !important;
    border-bottom: 0 !important;
}

.manageConnectionDrawer .ant-drawer-body{
    background-color: #EEF4FB !important;
    padding: 10px !important;
    padding-top: 0px !important;
}

