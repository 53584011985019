.customTable .ant-table-row {
    background-color: white !important
}

.customTable .ant-table-row:hover {
    background-color: white !important
}

.customTable .ant-table table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

.customTable .ant-table-thead th {
    font-size: 15px !important;
    font-weight: 800 !important;
    padding: 8px 4px !important;
    word-break: break-word;
}

.customTable .ant-table-tbody>tr>td:first-child {
    font-weight: 700 !important;
}

.customTable .ant-table .ant-table-expanded-row-fixed {
    margin: 0px;
}


.customTable .ant-table-tbody tr:first-child>td {
    padding: 0px !important;
    border-bottom: none !important;
}

.customTable .ant-table-tbody td {
    vertical-align: top !important;
}

.customTable .ant-table-tbody td:first-child {
    vertical-align: middle !important;
}


.customTable .ant-table-tbody>tr>td {
    border-bottom: 1px solid #8080809f !important;
    border-right: 1px solid #8080809f !important;
    padding: 0px !important;
}


.customTable .ant-table-thead>tr>th {
    border-bottom: 1px solid #8080809f !important;
    border-right: 1px solid #8080809f !important;
}

.customTable .ant-table-tbody>tr>td:last-child,
.customTable .ant-table-thead>tr>th:last-child {
    border-right: 1px solid #80808023 !important;
}

.customTable .ant-table-tbody>tr:last-child>td {
    border-bottom: none !important
}

.customTable ::-webkit-scrollbar-thumb {
    background-color: #cecdcdd7 !important;
    border-radius: 10px !important;
}

.customTable ::-webkit-scrollbar-thumb:hover {
    background-color: #cecdcdea !important;
}

.ant-select-selector {
    border-radius: 5px !important;
    border: 1px solid rgb(0 0 0 / 25%) !important;
    padding: 0px 10px !important;
    color: #000 !important;
    background-color: rgb(243 244 246) !important;
}

.ant-select-selection-item {
    font-weight: 500 !important;
}